import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type LocationFilterSelectProps = {
  viewMode?: boolean;
  options?: { label: string; value: string | undefined }[];
  paramKey?: string;
  fullWidth?: boolean;
};
const defaultOptions = [
  { label: 'All', value: undefined },
  { label: 'Job Sites', value: 'customer' },
  { label: 'Company Sites', value: 'company' },
];

export default function LocationFilterSelect({
  viewMode,
  options = defaultOptions,
  paramKey = 'siteType',
  fullWidth,
}: LocationFilterSelectProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const currentOption = options.find((option) => option.value === params[paramKey]);

  return viewMode ? (
    <React.Fragment>
      {currentOption?.value && (
        <Box component="span">
          Activity Type: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl fullWidth {...(!fullWidth && { sx: { width: 150 } })}>
      <InputLabel id={paramKey}>Activity Type</InputLabel>
      <Select
        labelId={paramKey}
        value={!params[paramKey] || params[paramKey] === 'All' ? -2 : params[paramKey]}
        label="Activity Type"
        onChange={(event) => {
          setURLParams({
            newParams: { [paramKey]: event.target.value === -2 ? '' : event.target.value },
          });
        }}
        size="small"
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
