import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton } from '@mui/material';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { QueryBatchReportRun } from '../../api/schema';
import Table from '../../component/table/Table';
import { batchReportRunCreateDownload, batchReportRunGetList, getRunListById, getRunListByIdFetching } from '../../redux/slices/reports/reportsSlice';
import { getUserData } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import axios from '../../utils/axios';

export const ReportHistory = (): ReactElement => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetching = useSelector(getRunListByIdFetching);
  const user = useSelector(getUserData);
  const runList = useSelector(getRunListById({ organizationId: user?.organization_id ?? '', reportId: id ?? '' }));

  useEffect(() => {
    if (id && user?.organization_id) {
      dispatch(batchReportRunGetList({ organizationId: user.organization_id, reportId: id }));
    }
  }, [dispatch, id, user?.organization_id]);

  const handleDownloadReport = useCallback(
    async (runId?: string) => {
      if (runId) {
        try {
          const res = await dispatch(batchReportRunCreateDownload({ runId })).unwrap();
          if (res.data.url) {
            const { data } = await axios({
              url: res.data.url,
              method: 'GET',
              responseType: 'blob',
            });
            const timeStamp = DateTime.local().toFormat('yyyyddMM_HHmmss');
            saveAs(data, `report_${runId}${timeStamp}.xlsx`);
          }
        } catch (error) {
          console.log(error); // eslint-disable-line
        }
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Mail Sent At',
        accessor: 'mail_sent_at',
        Cell: ({ value }: { value: string | null }) => (value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : ''),
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: 50,
        disableSortBy: true,
        Cell: ({ row }: { row: { original: QueryBatchReportRun } }) => (
          <IconButton onClick={() => handleDownloadReport(row.original.id)} disabled={!row.original.report_key}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [handleDownloadReport],
  );

  return <Table columns={columns} data={runList?.result} loading={fetching} />;
};
