const putFileToS3Api = async ({ uploadURL, file }: { uploadURL: string; file: File }) => {
  try {
    const result = await fetch(uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type ?? 'multipart/form-data',
      },
      body: file,
    });
    return result;
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

export default putFileToS3Api;
