import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryStorageUpload } from '../../api/schema';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import putFileToS3Api from '../../helpers/putFileToS3Api';
import toastOptions from '../../helpers/toastOptions';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  getStorageListUploadById,
  storageCreateUpload,
  storageFinishUpload,
  storageGetUpload,
  storageListUpload,
  storageListUploadFetching,
} from '../../redux/slices/storage/storageSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Documents: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [params] = useSearchParams();
  const filesRef = useRef<HTMLInputElement>(null);
  const downloadItemsRef = useRef<{ [key: string]: boolean }>({});
  const [uploading, setUploading] = useState(false);
  const [downloadItems, setDownloadItems] = useState<{ [key: string]: boolean }>({});
  downloadItemsRef.current = downloadItems;

  const paramsToUse = useMemo(() => {
    return {
      ...params,
      ownerId: id,
    };
  }, [params, id]);

  const documents = useSelector(getStorageListUploadById(paramsToUse));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: documents.pagination });

  const fetching = useSelector(storageListUploadFetching);

  useEffect(() => {
    dispatch(storageListUpload({ ...paramsToUse, ...paginationToGet }));
  }, [dispatch, paramsToUse, paginationToGet]);

  const download = useCallback(
    async (event: SyntheticEvent<HTMLButtonElement>, id?: string) => {
      event.preventDefault();

      if (id) {
        try {
          setDownloadItems((prevState) => ({ ...prevState, [id]: true }));
          const {
            data: { result },
          } = await dispatch(storageGetUpload(id)).unwrap();

          if (result?.download_url && result?.file_key) {
            const blob = await fetch(result?.download_url).then((result) => result.blob());
            saveAs(blob, result?.file_key.split('/').reverse()[0]);
          }
        } catch (error) {
          console.log(error); // eslint-disable-line
        } finally {
          setDownloadItems((prevState) => ({ ...prevState, [id]: false }));
        }
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'type',
        width: 50,
        Cell: () => <PictureAsPdfOutlinedIcon />,
      },
      {
        Header: 'Document',
        accessor: 'file_name',
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: { row: { original: QueryStorageUpload } }) =>
          row.original.id && downloadItemsRef.current[row.original.id] ? (
            <CircularProgress size={18} color="inherit" sx={{ m: 2 }} />
          ) : (
            <IconButton onClick={(event) => download(event, row.original.id)}>
              <FileDownloadOutlinedIcon />
            </IconButton>
          ),
        width: 50,
        disableSortBy: true,
      },
    ],
    [download],
  );

  /*
  <Button data-aid="uploadDocuments-button-upload" color="info" variant="contained" component="span" onClick={onAddDocuments}>
            {t('Upload File')}
            <VisuallyHiddenInput data-aid="uploadDocuments-input-files" type="file" accept="*" name="files" multiple={isMultipleFiles} ref={filesRef} />
          </Button>
   */
  const handleChangeDocuments = async (event: React.SyntheticEvent): Promise<void> => {
    const { files } = event.target as HTMLInputElement;

    if (files) {
      const filesList = Array.from(files);

      try {
        setUploading(true);
        const {
          data: { result: resultCreate },
        } = await dispatch(storageCreateUpload({ file_name: filesList[0].name, content_type: filesList[0].type })).unwrap();
        if (resultCreate?.upload_url && resultCreate?.id) {
          await putFileToS3Api({ uploadURL: resultCreate.upload_url, file: filesList[0] });
          await dispatch(storageFinishUpload(resultCreate.id)).unwrap();
          dispatch(storageListUpload({ ...paramsToUse, ...paginationToGet }));
        } else {
          toast.error('Error uploading file', toastOptions);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setUploading(false);
      }
    }
  };

  const onAddDocuments = () => {
    if (filesRef.current) {
      filesRef.current.click();
    }
  };

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={documents?.result}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          size="small"
          headerAddon={
            <Button
              color="primary"
              variant="contained"
              startIcon={uploading && <CircularProgress size={16} />}
              onClick={onAddDocuments}
              disabled={fetching || uploading}
            >
              New
              <input type="file" accept="application/pdf" name="files" ref={filesRef} onChange={handleChangeDocuments} style={{ display: 'none' }} />
            </Button>
          }
        />
      </Card>
    </>
  );
};
