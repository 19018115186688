import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type LocationFilterProps = {
  viewMode?: boolean;
  options?: { label: string; value: string | undefined }[];
  paramKey?: string;
};
const defaultOptions = [
  { label: 'All', value: undefined },
  { label: 'Job Sites', value: 'customer' },
  { label: 'Company Sites', value: 'company' },
];

export default function LocationFilter({ viewMode, options = defaultOptions, paramKey = 'siteType' }: LocationFilterProps): ReactElement {
  const [params, setURLParams] = useSearchParams();

  const currentOption = options.find((option) => option.value === params[paramKey]);

  const handleSitesClick = useCallback(
    (event) => {
      const { dataset } = event.target;
      setURLParams({ newParams: { [paramKey]: dataset.begin } });
    },
    [setURLParams, paramKey],
  );

  return viewMode ? (
    <React.Fragment>
      {currentOption?.value && (
        <Box component="span">
          Activity Type: <strong>{currentOption?.label}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <ButtonGroup variant="outlined" onClick={handleSitesClick}>
      {options.map((item) => (
        <Button key={item.label} variant={params[paramKey] === item.value ? 'contained' : 'outlined'} data-begin={item.value}>
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
