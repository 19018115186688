import { FormControlLabel, Switch } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement, useCallback } from 'react';
import useSearchParams from '../../hooks/useSearchParams';

type ActiveSwitchFilterProps = {
  viewMode?: boolean;
  param?: string;
  label?: string;
  defaultActive?: boolean;
  hideUncheckedLabel?: boolean;
};

export default function ActiveSwitchFilter({
  viewMode,
  param = 'active',
  label = 'Active',
  defaultActive = false,
  hideUncheckedLabel,
}: ActiveSwitchFilterProps): ReactElement | null {
  const [params, setURLParams] = useSearchParams();

  const handleChangeActive = useCallback(
    (event) => {
      const trueValue = defaultActive ? 'true' : '';
      const falseValue = defaultActive ? '' : 'false';
      setURLParams({ newParams: { [param]: event.target.checked ? trueValue : falseValue } });
    },
    [setURLParams, param, defaultActive],
  );

  const checked = defaultActive ? !!params[param] : !params[param] || params[param] === 'true';

  return viewMode ? (
    hideUncheckedLabel && !checked ? null : (
      <Box component="span">
        {label}: <strong>{checked ? 'Yes' : 'No'}</strong>
      </Box>
    )
  ) : (
    <FormControlLabel control={<Switch checked={checked} onChange={handleChangeActive} name={param} />} label={label} />
  );
}
